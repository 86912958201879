.view-container {
  padding-bottom: 5rem;
  width: 90%;
  margin: 0 auto;
}

.view-product {
  width: 100%;
}

.view-img-source {
  width: 100%;
}

.view-category {
  font-size: 1.5rem !important; //warning
  color: #393339;
  display: inline;
  padding: 4px 0;
}

.view-detail-title {
  font-size: 1.5rem;
  margin: 0.5rem 0;
  color: #393339;
  text-transform: uppercase;
}

.view-detail-container {
}

.view-detail-price {
  display: flex;
  margin-bottom: 0.5rem;
  align-items: baseline;
}

.price-off {
  font-size: 1rem;
  margin-right: 5px;
}

.price {
  font-size: 1.6rem;
  color: $primary;
  font-weight: 800;
  font-family: 'sans-serif';
}

.view-detail-raiting {
  margin-bottom: 1rem;
}

.view-detail-important {
  background: $lila;
  border-radius: 8px;
  padding: 0.6rem 1rem;
  font-size: 0.85rem;
  line-height: 1.2;
}

.view-detail-description {
  margin-top: 1.5rem;
  color: $gray;
  font-size: 0.9rem;
  margin-bottom: 3rem;
  line-height: 1.7;
  overflow: auto;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
}

.view-detail-button {
  display: flex;
  justify-content: flex-end;
  font-size: 2rem;
}

@media screen and (min-width: 575px) {
  .view-container {
    width: 70%;
  }
  .view-category {
    //background-color: white;
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 680px) {
  .view-container {
    width: 90%;
    display: flex;
    justify-content: space-between;
  }

  .view-product {
    width: 30%;
  }

  .view-detail-container {
    width: 68%;
  }

  .view-category {
    //background-color: white;
    font-size: 1rem;
  }
}

@media screen and (min-width: 968px) {
  .view-container {
    width: 70%;
  }

  .view-detail-important {
    font-size: 1.2rem;
  }

  .view-detail-description {
    font-size: 1.1rem;
  }
}
