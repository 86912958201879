.confirm {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: -7rem;
}
//data sale
.confirm__h3 {
  margin: 0 auto;
  padding-bottom: 5rem;
  text-align: center;
  color: $primary;
}

.confirm__data-sale {
  padding: 3.75rem 2.5rem 1.5rem;
  border: solid 1px $gray-lighter;
  border-radius: 3px;
  margin-bottom: 3rem;
  margin: 0 auto;
}

.data__nro {
  margin-top: -6.5rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.data__nro-title {
  font-size: 1.1rem;
  margin: 0;
  text-align: center;
}
.data__nro-number {
  background: $lila;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  display: inline-block;
  min-width: 177px;
  text-align: center;
  margin: 0 auto;
}

.data__list {
  display: flex;
  justify-content: space-between;
  padding: 0;
  flex-direction: column;
}

.data__list-li {
  padding: 0 0.5rem;
  border-bottom: dashed $gray 1px;
}
.data__list-li-not {
  padding: 0 0.5rem;
}

.data__list-title {
  margin: 0;
  line-height: 1.2;
  text-transform: uppercase;
  font-size: 1rem;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.data__list-description {
  margin: 0;
  margin-bottom: 1rem;
  line-height: 1.2;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

//data transfer
$size-transfer-9: 1.2rem;
$size-transfer-1: 1.1rem;
.confirm__data-transfer {
  margin: 0 auto 2rem;
  max-width: 515px;
}

.data__transfer-title {
  margin-bottom: 0;
  font-size: $size-transfer-9;
  text-align: center;
}

.data__transfer-cbu {
  font-size: $size-transfer-9;
  background: $primary-light;
  border-radius: 3px;
  padding: 1rem;
  line-height: 1.7;
  text-align: center;
  margin: 0;
  margin-bottom: 1rem;
}

.data__transfer-text-important {
  font-size: $size-transfer-9;
  text-align: center;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 3rem;
}

.data__transfer-email {
  font-size: $size-transfer-9;
  color: $primary;
}
//carrito
.carrito-total-container2 {
  border: solid 1px $gray-lighter;
}

.carrito-total-titulo2 {
  font-size: 1.4rem;
  margin: 0;
  margin: 1rem 0.5rem 0.5rem;
  font-weight: 800;
  padding-left: 0.7rem;
}
.carrito-total-items2 {
  width: 100%;
}

.carrito-total-item2 {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.5rem 1.2rem;
  color: $gray;
  line-height: 1.3;
  vertical-align: top;
}

.carrito-total-item-name2 {
  font-size: 0.9rem;
  padding-left: 1.2rem;
}

.carrito-total-item-price2 {
  text-align: right;
  padding-right: 1rem;
}

.carrito-resume-title2,
.carrito-resume-price2 {
  padding: 1rem 1.2rem;
  color: $text-color;
  font-weight: bold;
  background-color: $lila-lighter;
  font-size: 1.5rem;
}

//detalle

.confirm__data-details {
}

.data__details-buyer {
}

.details__buyer-cart {
}

.details__buyer-contact {
  padding: 1rem;
  border: solid 1px $gray-lighter;
  border-radius: 3px;
  margin: 0 auto;
  margin-bottom: 3rem;
}

.data__details-title {
  color: $primary;
  margin: 1rem 0 1.5rem;
}

.buyer__contact-container-h5 {
  font-size: 1.4rem;
  margin: 0;
  margin: 1rem 0.5rem 0.5rem;
  font-weight: 800;
}

.buyer__contact-container-p {
  padding: 0;
  margin: 0;
  margin: 1rem 0.5rem 0.5rem;
  color: $gray;
  font-size: 1.1rem;
}

.buyer__contact-container-clarify {
  padding: 0;
  margin: 0;
  margin: 1rem 0.5rem 0.5rem;
  color: $primary;
  font-size: 0.9rem;
  font-style: italic;
  a {
    color: $primary;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 768px) {
  .confirm__h3 {
    font-size: 2rem;
  }

  .carrito-total-container2 {
    width: 90%;
  }

  .data__details-buyer {
    display: flex;
    flex-direction: row;
    padding-bottom: 3rem;
    justify-content: space-between;
  }

  .carrito-total-container {
    width: 50%;
  }

  .details__buyer-contact {
    width: 50%;
    margin: 0;
    padding: 0;
  }
  //confirm data sale
  .data__list {
    flex-direction: row;
  }
  .data__list-li {
    border-right: dashed $gray 1px;
    border-bottom: none;
  }
  .confirm__data-transfer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }
  .data__transfer-cbu {
    width: 60%;
  }
  .data__transfer-text-important {
    font-size: 1.5rem;
    width: 90%;
    margin: 0;
  }
}

@media screen and (min-width: 990px) {
  .confirm__h3 {
    font-size: 2.5rem;
  }
  .buyer__contact-container {
    padding-left: 1rem;
  }

  .data__buyer-cart {
    width: 100%;
  }

  .carrito-total-container2 {
    width: 100%;
  }
}
@media screen and (min-width: 1100px) {
  .confirm__h3 {
    font-size: 3rem;
  }
  .carrito-total-container2 {
    width: 110%;
  }
}
@media screen and (min-width: 1200px) {
  .carrito-total-container2 {
    width: 130%;
  }
}
