.wrapper, %wrapper {
    margin: 0 auto;
    padding: 1rem;
    box-sizing: border-box;

    @media(max-width: $screen-sm) {
        max-width: 990px;
    }

    @media(min-width: $screen-md) {
        max-width: 990px;
    }

    @media(min-width: $screen-xl) {
        max-width: 1200px;
    }
}
