.home-container {
  //max-width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

//MAIN---------------------------------------------
.home-main {
  //margin-top: $header-height;
  margin-top: 0 !important;
  height: auto;

  &::before {
    content: '';
    display: block;
    background: url('../../assets/images/home/IMG_1229.jpeg') no-repeat center;
    height: 70vh;
    min-height: 470px;
    background-size: cover;
  }

  .home-main-container {
    .home-main-row {
      padding-bottom: 1rem;
      .title-home-containers {
        background: url('../../assets/images/home/encabezado-fondo.png') no-repeat center right #f6dada;
        display: flex;
        justify-content: center;
        margin: -4rem 2rem 0;
        height: auto;
        padding: 2rem 1rem;
        text-align: center;
        background-size: 90%;
        max-width: 80%;

        .home-main-title {
          color: #5a585a;
          font-weight: 800;
          text-transform: uppercase;
          padding: 0;
          font-size: 1.7rem;
          margin: 0;
        }
      }
    }
  }
}

.one {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

.animate {
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
}
@keyframes fadeRight {
  from {
    opacity: 0;
    -webkit-transform: translate(5px, 0, 0);
    -moz-transform: translate(5px, 0, 0);
    -o-transform: translate(5px, 0, 0);
    transform: translateX(5px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate(0, 0, 0);
    -moz-transform: translate(0, 0, 0);
    -o-transform: translate(0, 0, 0);
    transform: translateX(0, 0, 0);
  }
}

.fadeRight {
  -webkit-animation-name: fadeRight;
  -moz-animation-name: fadeRight;
  -o-animation-name: fadeRight;
  animation-name: fadeRight;
}

//SERVICES------------------------------------------
.home-services {
  // background-color: palegreen;
  background: white url('../../assets/images/sobre-mi/fondo-sobremi.png') no-repeat center bottom;
  background-size: 300%;
}

.services-items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 90%;
  flex-flow: row wrap;
}

.services-item-row {
  // background-color: paleturquoise;
  display: flex;
  justify-content: center;
  padding: 5px;
  width: 45%;
}

.services-button-container {
  //background: white url('../../assets/images/sobre-mi/fondo-sobremi.png') no-repeat center bottom;
  //background-size: 300%;
  padding-bottom: 10rem;
  display: flex;
  justify-content: center;
  .container-btn-sobre-mi {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}

.btt {
  @extend %btn-primary;
  @extend %btn-md;
  width: 100% !important;
  height: 25px;
}

.services-item-about {
  display: flex;
  flex-direction: column;
  align-items: center;
  h5 {
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 1.2rem;
  }
}
.services-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  position: relative;
  width: 97%;
  margin: 0 auto;

  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    width: 80%;
    height: 100%;
    z-index: 2;
    background: url(../../assets/images/encabezado/fondo-tit-sobremi.svg) no-repeat center;
    background-size: contain;
  }

  h2 {
    position: relative;
    z-index: 4;
    font-size: 3rem;
    color: #f2bcbd;
    font-weight: 200 !important;
  }
}

//SHOP---------------------------------------------
.home-shop {
  background-color: palevioletred;
}

.home-shop {
  background: $lila;
  padding-top: 0;
  padding-bottom: 8rem;
  .shop-container-menu-tienda {
    margin-top: 3rem;
    .item-shop-title,
    .item-shop-title-h3 {
      text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
      color: white;
      font-weight: 700;
      text-transform: uppercase;
      margin: 0;
      font-size: 1.2rem;
    }
    .item-shop-title-h3 {
      font-size: 1.5rem;
    }
  }

  .menu-top-grid,
  .menu-mid-grid {
    //background-color: palegreen;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 90%;
    flex-flow: row wrap;
  }

  .item-tienda-home {
    display: flex;
    justify-content: center;
    padding: 0;
    padding-bottom: 1rem;
    width: 45%;
    height: 150px;
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 0 1rem;
  }

  .item-tienda-home-lg {
    width: 95%;
    height: 468px;
    .item {
      padding: 0;
      margin: 0;
    }
  }

  .item-tienda-home-mosaicos {
    background: white url('../../assets/images/tienda/bg-mosaicos.jpg') no-repeat center;
    background-size: cover;
  }

  .item-tienda-home-recetarios {
    background: white url('../../assets/images/tienda/bg-recetarios.jpg') no-repeat center;
    background-size: cover;
  }

  .item-tienda-home-pack {
    background: white url('../../assets/images/tienda/bg-pack-ahorro.jpg') no-repeat center;
    background-size: cover;
  }

  .item-tienda-home-guias {
    background: white url('../../assets/images/tienda/bg-guias.jpg') no-repeat center;
    background-size: cover;
  }

  .item-tienda-home-consultas {
    background: white url('../../assets/images/tienda/bg-consultas.jpg') no-repeat center;
    background-size: cover;
  }
}

.home-shop {
  .item-tienda-home-lg {
    height: 300px;
  }
}

.shop-container-title-top {
  position: absolute;
}

.title-section-card {
  font-weight: 900;
  background-color: white;
  display: inline-block;
  margin: -5rem 0 0;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  padding: 1rem 2rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  line-height: 1;
  font-size: 2.3rem;
}
.title-section-card-rosa {
  color: $lila;
}

.shop-botton-link {
  // background-color: black;
  display: flex;
  justify-content: center;
}

.shop-button-go {
  @extend %btn-primary;
  @extend %btn-md;
  margin-top: 1rem;
  font-size: 2rem;
  padding: 10px 32px;
  margin: 16px 0 0;
  background-color: $primary;
  color: white !important;
  border: none;

  &:hover {
    background-color: $primary-light;
  }
}

//CHANGES------------------------------------------
.home-changes {
  //background-color: plum;
  //height: 200px;
  position: relative;
}
.h2 {
  background: white url('../../assets/images/transformaciones/transformaciones-fondo.jpg') no-repeat center;
  background-size: cover;
  text-align: center;
  margin-top: 3rem;
  position: relative;
  height: 294px;
  padding: 5rem 0;
  margin: 0;
  z-index: 1;
}

.transformaciones {
  //background-color: palegreen;
  //position: relative;
  z-index: 1;
  color: $primary;
  font-size: 2.3rem;
  font-weight: 400;
  margin: 0 0 0.5rem;
  padding: 2.1rem;
  position: relative;
  &::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: url('../../assets/images/transformaciones/tranformaciones-fondo-tit.svg') no-repeat center;
    background-size: contain;
  }
}

.botontransfo {
  text-decoration: none;
  display: inline-block;
  margin: 2rem;
  padding: 1rem 2rem;
  border: solid 3px white;
  color: white;
  font-weight: 900;
  border-radius: 0.5rem;
  transition: all 0.3s ease;
  font-size: 1.3rem;
}

.botontransfo:hover {
  opacity: 0.5;
}

//FLORES-----------------------------------------------
.flores-faq {
  height: 130px;
  width: 100%;
  background: url('../../assets/images/faq/fondo-faq.png') no-repeat center bottom;
  background-size: contain;
}

//FAQ--------------------------------------------------
.home-faq {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background-color: $gray;
  padding-top: 0;
  padding-bottom: 8rem;
  .faq-container {
    width: 80%;
    margin: 0 auto;
    .title-section-card-b {
      font-size: 2rem;
    }
    .faq-container-button {
      display: flex;
      justify-content: center;
    }
  }
  h3 {
    color: white;
    font-weight: 900;
    font-size: 2.5rem;
    margin: 0 auto;
    margin-top: 5rem;
    margin-bottom: 0.5rem;
    width: 80%;
  }
}
.faq-container-button {
  margin: 0 !important;
  padding: 0 !important;
}
.button-faq {
  @extend %btn-primary;
  @extend %btn-md;
  margin-top: 1rem;
  width: 115px !important;
  height: 40px !important;
  font-size: 2rem;
  padding: 16px 32px;
  margin: 16px 0 0;
  //max-width: 50%;
  display: flex;
  justify-content: center;
  background-color: $primary-light;
  color: $gray !important;
  &:hover {
    opacity: 0.8;
  }
}

.faq-text-top {
  .title-section-card-b {
    font-weight: 900;
    background-color: white;
    display: inline-block;
    margin: -5rem 0 0;
    position: relative;
    z-index: 2;
    padding: 1rem 2rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    line-height: 1;
    font-size: 2.3rem;
  }
}

.title-section-card-gris {
  color: $gray;
}

@media screen and (min-width: 575px) {
  .services-button-container {
    //background: white url('../../assets/images/sobre-mi/fondo-sobremi.png') no-repeat center bottom;
    background-size: 200%;
  }

  .servicios-home {
    background-size: 300%;
  }

  .home-services {
    background-size: 200%;
  }

  .home-main {
    // margin-top: $header-height;
    position: relative;
    padding: 0;
    height: calc(100vh - 147px);
    margin-bottom: 5rem;
    &::before {
      content: '';
      width: 60%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      height: calc(100vh - 147px);
    }
    .home-main-container {
      .home-main-row {
        .title-home-containers {
          background-size: contain;
          width: 45%;
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: left;
          z-index: 1;
          height: calc(80vh - 147px);
          margin: 2rem 5rem 0 0;

          .home-main-title {
            font-size: 2.1rem !important;
            padding: 0 0.5rem !important;
            margin-bottom: 1rem !important;
            border-bottom: none !important;
          }
        }
      }
    }
  }

  .services-items {
    flex-flow: row nowrap;
  }

  .services-item-about {
    h5 {
      font-size: 1.5rem;
    }
  }

  //SHOP------------------------------------------------

  .item-tienda-home {
    .item {
      .item-shop-title {
        font-size: 1.5rem;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .home-services {
    padding-bottom: 5rem;
  }

  .services-items {
    width: 70%;
  }

  .item-img {
    //width: 258px;
    height: auto;
  }

  .services-button-container {
    //background: white url('../../assets/images/sobre-mi/fondo-sobremi.png') no-repeat center bottom;
    background-size: 100%;
  }

  .services-title {
    &::before {
      content: ' ';
      display: block;
      width: 100%;

      height: 2px;
      background: $primary;
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 1;
    }

    &::after {
      width: 60%;
      height: 100%;
      top: 20%;
      max-height: 60%;
    }

    h2 {
      font-size: 4rem;
      font-weight: 300 !important;
    }
  }
  //SHOP---------------------------------------------

  .menu-top-grid,
  .menu-mid-grid {
    //background-color: palegreen;
    width: 75%;
  }

  .menu-top-grid {
    .item-tienda-home {
      width: 45%;
      height: 218px;
    }
  }

  .item {
  }

  .menu-mid-grid {
    .item-tienda-home-lg {
      width: 95%;
      height: 455px;
      max-height: 100%;

      .item-tienda-home-consultas {
      }
    }
  }
  .title-section-card {
    font-size: 3.75rem;
  }
  //CHANGES---------------------------------------------
  .transformaciones {
    font-size: 4rem;

    &::before {
      content: ' ';
      display: block;
      width: 100%;
      height: 2px;
      background: $primary;
      position: absolute;
      top: 50%;
      left: 0;
      z-index: -1;
    }
  }

  .botontransfo {
    font-size: 1.5rem;
  }

  .flores-faq {
    height: 207px;
  }

  //FAQ-------------------------------------------------
  .faq-text-top {
    .title-section-card-b {
      font-size: 4rem !important;
    }
    h3 {
      font-size: 3rem;
    }
  }
}

@media screen and (min-width: 1080px) {
  .btt {
    font-size: 1.4rem;
    padding: 1rem;
  }

  .home-services {
    background-size: 100%;
  }
  //SHOP---------------------------------------------
  .shop-container-menu-tienda {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
    margin: 0 auto;
  }
  .menu-top {
    //background-color: palegreen;
    width: 70%;
  }
  .menu-mid {
    width: 35%;
  }
  .menu-top-grid,
  .menu-mid-grid {
    width: 100%;
  }

  .menu-top-grid {
    .item-tienda-home {
      width: 45%;
    }
  }
  //CHANGES---------------------------------------------
  .botontransfo {
    font-size: 2rem;
  }
}
