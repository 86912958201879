.card-product-container {
  width: 100%;
  padding-bottom: 2rem;
}
//

.Toastify__progress-bar-theme--light {
  background: linear-gradient(to right, white, $primary-light, $primary);
}
.Toastify__toast {
  //background-color: white;
  // background: url(../../assets/images/encabezado/fondo-flores-rosas.png) no-repeat center;
  //background-size: auto;
}
.Toastify__toast-body {
  font-family: 'Nunito sans';
}
//

.card-product-img {
  margin-bottom: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: white;
  margin: 0px 10px;
}

.img-container {
  align-items: center;
  display: flex;
  height: 144px;
  justify-content: center;
  width: 144px;
}

.img-source {
  width: 100% !important;
  height: 100% !important;
}

.sale-text {
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  color: $gray;
  background: $lila-lighter;
  width: 46px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-size: 0.9rem;
  z-index: 1;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
}

.label-text {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(50%);
  font-size: 1rem;
  color: white;
  background: $lila;
  padding: 3px 16px;
  z-index: 1;
  line-height: 1.2;
  display: inline-block;
  font-weight: 900;
}

.card-product-overlay {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: 0.3s all ease;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  display: none;
}

.card-product-img:hover .card-product-overlay {
  opacity: 1;
}

.botonn1 {
  transform: translateX(20px);
  transform: translatey(-10px);
}

.card-product-text {
  margin: 20px 5px 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  min-height: 6rem;
}

.card-product-text__name {
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
}
.card-product-text__price {
  font-size: 1.1rem;
  text-align: center;
  margin: 0px 0px 5px;
}

.botones-mobile {
  display: flex;
  margin-top: 1rem;
  align-items: center;
  justify-content: space-around;
}

.botones-mobile-view {
  //@extend %btn-secondary;
  border: solid 2px $primary;
  border-radius: 5px;
  width: 40%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  //margin: 0px 10px;
  color: $primary;
  font-weight: bold;
  font-size: 1.3rem;
}

.botones-mobile-addToCart {
  //@extend %btn-primary;
  @extend %btn;
  background-color: $primary;
  //border: solid 3px $primary;
  color: white;
  border-radius: 5px;
  width: 40%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  &:hover {
    background-color: $primary-light;
  }
}

.card-product-price__tachado {
  text-decoration: line-through;
  font-size: 80%;
  opacity: 0.7;
  display: inline-block;
}

@media screen and (min-width: 370px) {
  .card-product-container {
    width: 50%;
  }
}

@media screen and (min-width: 575px) {
  .card-product-container {
    width: 33%;
  }

  .contenedor-img {
    height: 210px;
    width: 210px;
  }
}

@media screen and (min-width: 680px) {
}

@media screen and (min-width: 768px) {
  .botones-mobile {
    display: none;
  }

  .card-product-overlay {
    display: flex;
  }

  .img-container {
    height: 210px;
    width: 210px;
  }

  .card-product-img {
    height: 210px;
  }
}

@media screen and (min-width: 968px) {
  .card-product-container {
    width: 25%;
  }

  .card-product-img {
    height: 300px;
  }

  .img-container {
    height: 300px;
    width: 300px;
  }
  .img-source {
    width: 300px;
    height: 300px;
    max-width: 100%;
    object-fit: contain;
  }
}
