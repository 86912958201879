.margintop {
  padding-top: 7rem;
}

.encabezado__container {
  background: url(../../assets/images/encabezado/fondo-flores-rosas.png) no-repeat center;
  background-size: cover;
  height: 395px;
  position: relative;
  z-index: 1;
  padding-right: 2rem;
  padding-left: 2rem;
}

.encabezado__container-title {
  width: 100%;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.title__container {
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: $primary;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: -15px;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    height: 150%;
    z-index: 2;
    background: url(../../assets/images/encabezado/fondo-tit-sobremi.svg) no-repeat center;
    background-size: contain;
  }

  .title__container-h1 {
    position: relative;
    z-index: 4;
    font-size: 3rem;
    color: #f2bcbd;
    font-weight: 300;
    text-transform: capitalize;
  }
}

@media screen and (min-width: 675px) {
  .title__container {
    .title__container-h1 {
      font-size: 4.3rem;
    }
  }
}
