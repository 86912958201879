.footer {
  width: 100%;
  text-align: center;
  text-align: center;
  padding-bottom: 10px;
  background: $text-color;
  color: white;
  padding: 2rem 0 0;
}
.footer-container-top {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.footer-logo-container {
  margin: 0;
  font-size: 1.3rem;
  font-weight: 300;
  p {
    //esto se debe arreglar
    margin: 0;
    font-size: 1.3rem;
    font-weight: 300;
    color: white;
    text-align: center;
  }
}

.footer-container-menu {
  justify-content: center;
  padding: 0.5rem;
}
.footer__link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
  font-weight: 300;
  padding: 0.3rem;
  &:hover,
  &:focus {
    opacity: 0.7;
  }
}
.footer-menu-redes {
  display: flex;
  align-items: center;
  a {
    padding: 1rem 0.5rem;
  }
}

.footer-container-botton {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fff9f5;
  text-align: center;
  color: #666666;
  font-weight: 300;
}

.footer-container-botton-left,
.footer-container-botton-right {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  align-items: center;
  margin-left: 3px;
  .a {
    font-weight: 700;
    color: #333;
    font-size: 1rem;
  }
}

.footer-container-botton-left {
  width: 50%;
}

.footer-container-botton-right {
  width: 50%;
}
.rayo {
}
.felipe {
}

@media screen and (min-width: 768px) {
  .footer-container-top {
    justify-content: center;
  }
  .footer__link {
    font-size: 1.1rem;
  }
  .footer-container-menu {
    display: flex;
    ul {
      display: flex;
      align-items: center;
      li {
      }
    }
  }

  .footer-container-botton-left,
  .footer-container-botton-right {
    flex-direction: row;
    gap: 1rem;
    .a {
      font-size: 1.3rem;
    }
    p {
      font-size: 1.3rem;
    }
  }
}

@media screen and (min-width: 968px) {
  .footer-container-top {
    width: 80%;
    margin: auto;
    flex-direction: row;
    justify-content: space-between;
  }
}

@media screen and (max-width: 768px) {
  .footer-container-botton {
    flex-direction: column;

    .footer-container-botton-left {
      border-bottom: 1px solid $gray;
      width: 9rem;
      padding-bottom: 0.6rem;
    }

    h3 {
      display: none;
    }
  }
}
