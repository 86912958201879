.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  height: calc(100vh);

  overflow: auto;
  //effect
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}
.modal-body {
  background-color: white;
  padding: 10px;

  border-top: 1px solid #eee;
  -webkit-overflow-scrolling: touch;
  //margin-bottom: -5rem;
}

.modal-container.show {
  //effect
  opacity: 1;
  pointer-events: visible;
}

.modal-content {
  max-height: calc(90vh);
  width: 95%;
  position: absolute;
  background-color: white;
  //effect
  transform: translateY(-200px);
  transition: all 0.3s ease-in-out;
}

.modal-container .modal-content {
  transform: translateY(0);
}

.modal-header,
.modal-footer {
  padding: 10px;
  display: none;
}

.body-container-close {
  display: flex;
  justify-content: flex-end;
}
.body-close {
  display: flex;
  width: 20px;
  justify-content: right;

  .a {
    filter: invert(100%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
  }

  .a:hover {
    filter: invert(100%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(58%) contrast(19%);
  }
}

@media screen and (min-width: 475px) {
  .modal-content {
    width: 80%;
  }
}

@media screen and (min-width: 675px) {
  .modal-content {
    height: 45%;
  }
}

@media screen and (min-width: 700px) {
  .modal-content {
    width: 600px;
  }
}

@media screen and (min-width: 800px) {
  .modal-content {
    width: 700px;
  }
}

@media screen and (min-width: 950px) {
  .modal-content {
    width: 950px;
  }
}
