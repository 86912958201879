/*------------------------------------*\
    # Margins
\*------------------------------------*/

// scss-lint:disable ImportantRule

@for $i from 0 through 10 {
    .m-#{$i} {
        margin: #{$i}rem !important;
    }

    .mb-#{$i} { margin-bottom: #{$i}rem !important;  }
    .ml-#{$i} { margin-left: #{$i}rem !important; }
    .mt-#{$i} { margin-top: #{$i}rem !important; }
    .mr-#{$i} { margin-right: #{$i}rem !important; }

    .my-#{$i} {
        margin-top: #{$i}rem !important;
        margin-bottom: #{$i}rem !important;
    }

    .mx-#{$i} {
        margin-right: #{$i}rem !important;
        margin-left: #{$i}rem !important;
    }
}


// Paddings
@for $i from 0 through 5 {
    .p-#{$i} {
        padding: #{$i}rem !important;
    }

    .pb-#{$i} { padding-bottom: #{$i}rem !important; }
    .pl-#{$i} { padding-left: #{$i}rem !important; }
    .pt-#{$i} { padding-top: #{$i}rem !important; }
    .pr-#{$i} { padding-right: #{$i}rem !important; }

    .py-#{$i} {
        padding-top: #{$i}rem !important;
        padding-bottom: #{$i}rem !important;
    }

    .px-#{$i} {
        padding-right: #{$i}rem !important;
        padding-left: #{$i}rem !important;
    }
}
