.section-container {
  padding: 5rem 0;
  background-color: $gray-9e;

  padding-right: 1.5rem;
  padding-left: 1.5rem;
}
.section__images {
  width: 90px;
}
.section__texts {
  width: calc(100% - 90px);
  padding-left: 1rem;
  text-align: left;
  text-align: center;
  h5 {
    margin-top: 0;
    margin: 1rem 0;
    color: $primary;
    font-size: 17px;
  }
  p {
    padding-bottom: 1rem;
    border-bottom: solid 1px $primary;
    font-size: 1rem;
    color: $gray;
  }
}

@media screen and (min-width: 768px) {
  .flex-column {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .flex-row {
    flex-direction: column;
    width: 32%;
  }
  .section__texts {
    p {
      font-size: 1.1rem;
      border-bottom: solid 0px $primary;
    }
  }
}
