.navBar {
  position: sticky;
  top: 0;
  max-height: $header-height;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  z-index: 5;
  background-color: white;
  transition: all 0.3s ease;
}

.navBar__icon__svg {
  background-color: white;
  width: 80px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: solid 1px #f2f2f2;
  border-left: solid 1px #f2f2f2;
  cursor: pointer;
  position: relative;
  z-index: -1; ///////test
}

.icon__svg__open {
  height: 25px;
}

.img-icono-close-open {
  height: 25px;
  width: 25px;
}

.nro-carrito {
  font-size: 12px;
  color: white;
  text-align: center;
  line-height: 18px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: $primary;
  position: absolute;
  top: 30%;
  right: 20%;
  font-weight: bold;
  color: black; // bug?
}

.navBar__menu {
  background: white;
  justify-content: start;
  width: 300px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -100%;
  transition: all 0.3s ease;
  border-right: 1px solid #ccc;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.pepe {
  background-color: $primary;
  height: $header-height;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 13px;
  z-index: 2;
}

.container__icon__close {
  max-height: $header-height;
  max-width: 80px;
}

.icon__svg__close {
  height: 25px;
}

.navBar__menu--active {
  left: 0%;
  transition: all 350ms ease;
}

//menu nava-bar
.menu__left,
.menu__right {
  height: auto;
}

.menu__item {
  display: block;
  text-align: left;

  p {
    color: #393338;
    font-size: 1rem;
    margin: 0;
    padding: 0;
  }
}

.menu__link {
  font-size: 1rem;
  height: auto;
  padding: 16px;
  line-height: 1.2;
  display: flex; // center
  justify-content: center; //center
  z-index: 3;
  align-self: center;
}

.underlined {
  //background-color: yellow;
  position: relative;

  &:hover {
    transition: all 0.3s ease;
    -webkit-transition: 0.5s ease;
    opacity: 0.7;
  }
}

.left-redes {
  justify-content: start;
}

.menu-bars {
  font-size: 2rem;
}

.nav-menu__icon {
  color: white;
}

.menu__redes {
  display: flex;
  height: 50px;
}

.menu__redes__link {
  display: flex;
  height: 50px;
}

.menu__redes__icono {
  height: auto;
  padding: 0 1rem;

  &:hover {
    opacity: 0.3;
    transition: all 0.3s ease;
  }
}

/*-----------------------------------------------*/

.header--resize {
  height: 64px;
  background-color: white;
  transition: all 0.3s ease;

  .navBar__icon__svg {
    height: 100%;
  }

  .menu__logo {
    height: 60px;
    width: 60px;
    padding-top: 0;
  }

  .menu__logo__svg {
    max-height: 100%;
  }
}

.header__logo {
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.header__logo__img {
  margin: 0 auto;
  height: 100%;
  width: 100%;
  transition: all 0.3s ease;
}

.menu__logo {
  position: fixed;
  top: 0;
  left: 50%;
  right: 50%;
  transform: translate(-50%, +00%);
  height: 80px;
  width: 80px;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  transition: all 0.3s ease;
}

.menu__logo__svg {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  transition: all 0.3s ease;
}

@media screen and (min-width: 992px) {
  .navBar {
    position: sticky;
    max-height: $header-height;
    transition: all 0.3s ease;
  }

  .navBar__icon__svg--none {
    display: none;
  }

  .navBar__menu {
    display: flex;
    position: static;
    justify-content: space-between;
    width: 100%;
    height: $header-height;
    border: none;
    box-shadow: none;
    transition: all 0.3s ease;
  }

  .menu__left {
    display: flex;
    justify-content: flex-start;
    width: 50%;
  }

  .menu__item p {
    font-size: 16px;
  }

  .menu__right {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    width: 50%;
  }

  .pepe {
    display: none;
  }

  .menu__logo {
    height: 150px;
    width: 150px;
    padding-top: 5px;
    transition: all 0.3s ease;
  }

  .menu__logo__svg {
    height: 100%;
    width: 100%;
    transition: all 0.3s ease;
  }

  .header--resize {
    height: 97px;
    transition: all 0.3s ease-in-out;

    .menu__logo {
      height: 90px;
      width: 90px;
      transition: all 0.3s ease;
    }

    .menu__logo__svg {
      height: 100%;
      width: 100%;
      transition: all 0.3s ease;
    }
  }

  .underlined {
    //background-color: yellow;
    position: relative;

    &:hover {
      transition: all 0.3s ease;
      -webkit-transition: 0.5s ease;
      opacity: 0.7;
    }
  }

  .underlined::before {
    content: '';
    position: absolute;
    bottom: -8px;
    width: 0px;
    height: 4px;
    background-color: $primary;
    left: 50%;
    margin-left: -15px;
    z-index: 0;
    transition: all 0.3s ease;
    -webkit-transition: 0.5s ease;
  }

  .underlined:hover:before {
    width: 30px;
  }
}
