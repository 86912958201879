.primary-button {
  @extend %btn;
  @extend %btn-primary;
  font-size: 1.1rem;
  padding: 0.5rem 1rem;

  p {
    padding: 0;
    margin: 0;
    color: white !important; // be careful
    font-weight: 600 !important; // be careful
  }

  &:hover {
    background: $primary-light;
    border-color: $primary-light;
  }
}
