input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
}

.caja {
}

.form {
  label {
    color: $gray;
  }
}

.form-title {
  font-size: 1.4rem;
  font-weight: 600;
}

.form-input {
  width: 100%;
  padding: 6px 10px;
  margin: 10px 0px;
  box-sizing: border-box;
  display: block;
}

.form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.form {
  input {
    width: 100%;
    padding: 6px 10px;
    margin: 10px 0px;
    box-sizing: border-box;
    display: block;
  }
}
.form-row-name {
  width: 48%;
}

.form-row-lastName {
  align-self: flex-end;
  width: 48%;
}

.form-control {
  padding: 0.25em 0.5em;
}
@media screen and (min-width: 678px) {
  .form-container {
    width: 65%;
  }
}

.carrito-finalizar {
  @extend %btn;
  @extend %btn-primary;
  @extend %btn-md;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  width: 100%;
  //margin: 1rem;
}

.carrito-finalizar-next {
  width: 40%;
  margin: 1rem;
}

.span_error {
  color: red;
}

.input_error {
  border: solid 2px $red-dd !important;
}
