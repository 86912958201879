.wizard-body {
  width: 100%;
}

.calendar-title {
  font-size: 1.5rem;
  padding: 0;
  margin: 0;
}
.calendar-time-picker-container {
  // background-color: aqua;
  border: 1px solid $primary;
  display: flex;
  padding: 1rem;
  justify-content: space-around;
}

.calendar-picker-container {
  width: 90%;
  max-width: 100%;
}

.calendar-select-date {
  display: flex;
}

//css select
.react-select-containerC {
  width: 100%;
}

.react-selectC__control {
  display: flex;
  border: none;
  .react-selectC__indicators {
    display: none;
  }

  .react-selectC__value-container {
    text-align: center;
    margin: 0;
    padding: 0;
    display: none;
  }
}

.react-selectC__control--is--focused {
}

.react-selectC__indicator-separator {
  display: none;
}
.react-selectC__value-container {
  .react-selectC__input-container {
  }
}

.react-selectC__input {
  //background-color: tomato;
}

.react-selectC__indicators {
  background-color: transparent;
}

.react-selectC__indicator {
  //background: url(../../assets/images/tienda/loupe.svg) no-repeat 80% center;
  display: none;

  .css-tj5bde-Svg {
    display: none;
  }
}
.react-select-containerC {
  .react-selectC__menu {
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    //height: 100%;
    .react-selectC__menu-list {
      height: 120%;
      width: 100%;
      padding: 0;
      overflow: auto;

      .react-selectC__option {
        background-color: $gray-lighter;
        border-bottom: 1px solid $gray;
      }

      .css-1n7v3ny-option {
        background-color: $primary-light;
      }

      .react-selectC__option--is-selected {
        background-color: $primary;
      }
    }
  }
}

.react-selectC__option {
  border: none;
  .react-selectC__option--is-selected {
    color: black;
  }
}

//calendar css
.react-calendar {
  width: 92%;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  //display: none; // flex
  height: 44px;
  margin-bottom: 1em;

  .react-calendar__navigation__arrow {
    //display: none;
  }

  .react-calendar__navigation__label__labelText {
    text-transform: capitalize;
    //background: url(../../assets/images/tienda/loupe.svg) no-repeat 80% center;
  }
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
  //background-color: white;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: capitalize;
  font-weight: bold;
  //font-size: 0.75em;
  font-size: 1em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  color: white; //mmC
  padding: 1rem 0;
  border: 1px solid $gray-lighter;
  abbr {
    text-decoration: none; //mmc
  }
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days {
  //mmc
  //border: 1px solid $gray-lighter;

  button {
    border: 1px solid $gray-lighter;
    text-align: right;
    padding-right: 0.5rem;
    color: $gray;
  }
}
.react-calendar__month-view__days__day {
  //mmc
  background-color: yellow;
}

.react-calendar__month-view__days__day--weekend {
  //color: #d10000;
  color: white; //mmc
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575; //mmc
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  //padding: 10px 6.6667px;
  padding: 1rem 0; //mmc
  background: none;
  text-align: center;
  line-height: 16px;
  background-color: #dcdcdc; //mmc
}
.react-calendar__tile:disabled {
  //background-color: #f0f0f0; color de
  background-color: white;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: $primary-light;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: $primary-light;
}
.react-calendar__tile--hasActive {
  background: $primary;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: $primary;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: $primary;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

//time-picker css
.calendar-time-container {
  width: 15%;
  border: 1px solid gray;
  border-top: none;
}

.time-container {
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  //background-color: green;
}

.time-select {
  background-color: $gray-lighter;
  display: flex;
  justify-content: center;
  cursor: pointer;
  border-bottom: 1px solid gray;
}

.time-select__current {
  background-color: $primary;
}

@media screen and (min-width: 678px) {
  .wizard-body {
    width: 65%;
  }
}

@media screen and (max-width: 470px) {
  .calendar-time-picker-container {
    // background-color: aqua;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 390px;
    padding-bottom: 10rem;
  }

  .calendar-picker-container {
    width: 100%;
    max-width: 100%;
  }

  .calendar-time-container {
    width: 100%;
    border: none;
  }

  .react-calendar {
    width: 100%;
    max-width: 100%;
  }

  .time-container {
    // border-top: 1px solid gray;
    //border-bottom: 1px solid gray;
    border: none;

    //padding-top: 1rem;
    // background-color: green;
    //height: 150px;
  }

  .react-selectC__control {
    display: none;
    border: none;
    .react-selectC__indicators {
      display: flex;
    }

    .react-selectC__value-container {
      text-align: center;
      margin: 0;
      padding: 0;
      display: flex;
    }
  }

  .react-select-containerC {
    .react-selectC__menu {
      .react-selectC__menu-list {
        .react-selectC__option {
          border: 1px solid $gray;
          text-align: center;
        }

        .css-1n7v3ny-option {
          //background-color: $primary-light;
          background-color: $gray-lighter;
        }

        .react-selectC__option--is-selected {
        }
      }
    }
  }
}
