// Flex

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-start {
  align-items: flex-start;
}

.flex-center {
  align-items: center;
}

.flex-stretch {
  align-items: stretch;
}

.flex-end {
  align-items: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.space-around {
  justify-content: space-around;
}

.space-between {
  justify-content: space-between;
}

.flex-1 {
  flex: 1;
}

.align-self-end {
  align-self: flex-end;
}
