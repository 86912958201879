.carrito-container {
  display: flex;
  width: 90%;
  margin-top: -7.5rem;
  justify-content: space-between;
}
.carrito-container-empty {
  flex-direction: column;
  z-index: 9;
}
.title-empty {
  padding-top: 8rem;
  padding-bottom: 4rem;
  text-align: center;
  font-size: 30px;
}
.container-empty-link {
  display: flex;
  justify-content: center;
}
.link-empty {
  font-size: 1.5em;
  padding: 0.5em;
  border-radius: 10px;
  color: white;
  background-color: $primary;
  &:hover {
    background-color: $primary-light;
  }
}
.carrito-cards {
  background-color: white;
  width: 100%;
  z-index: 4;
}

.carrito-card {
  display: flex;
  padding: 1rem;
  margin-bottom: 2rem;
  min-height: 270px;
  border: solid 1px $gray-lighter;
}
.carrito-img {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 90px;
  height: 90px;
}
.img-source {
  width: 90px;
  height: 90px;
  max-width: 100%;
  object-fit: contain; //magic
}
.carrito-content {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 1rem;
}

.content-title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.content-title__h6 {
  font-weight: 800;
  margin: 0;
  padding-right: 1rem;
  font-size: 16px;
  color: $primary;
}

.content-delete {
  background-color: transparent;
  border: none;
  width: 14px;
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: flex-end;
  padding-right: 0.5rem;
  align-items: center;
  font-size: 20px;
  height: 44px;
  width: 10%;
  cursor: pointer;
  .delete-icon {
    color: #dc3545;
  }
}

.content-description {
}

.content-descripcion-corta-carrito {
  font-size: 1rem;
  color: $gray;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
}

.content-botton-precio {
  display: flex;
  justify-content: space-between;
  height: 42px;
}
.content-quantity-selector {
  padding: 0;
  height: 100%;
  display: flex;
}
.quantity {
  width: 20px;
  padding: 0.5rem;
  border: solid 1px $gray;
  text-align: center;
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.btn-quantity {
  border: solid 1px $gray;
  background: white;
  padding: 0.5rem;
  color: $gray;
  cursor: pointer;
}
.btn-disabled {
  cursor: not-allowed;
  background-color: rgb(229, 229, 229) !important; // be careful
  pointer-events: none;
}

.content-precio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 1rem;
    padding-left: 8px;
  }
  .card-product-price__tachado {
    font-size: 1rem;
  }
}

@media screen and (min-width: 478px) {
  .carrito-card {
    min-height: 190px;
  }
  .content-precio {
    p {
      font-size: 1.5rem;
    }
  }
}
@media screen and (min-width: 678px) {
  .carrito-cards {
    background-color: transparent;
  }

  .carrito-card {
    width: 70%;
    margin: auto;
    margin-bottom: 2rem;
    background-color: white;
  }
}

@media screen and (min-width: 768px) {
  .carrito-cards {
  }

  .carrito-card {
    width: 85%;
  }
  .carrito-img {
    width: 150px;
    height: 150px;
  }

  .img-source {
    width: 150px;
    height: 150px;
  }
}

@media screen and (min-width: 978px) {
  .carrito-container {
    width: 80%;
    margin-top: -8rem;
  }
  .carrito-cards {
    max-width: 70%;
  }

  .carrito-card {
    width: 90%;
    margin: auto;
    margin-bottom: 2rem;
    background-color: white;
    min-height: 120px;
  }
  .carrito-content {
    width: 80%;
  }

  .content-title__h6 {
    font-size: 21px;
  }

  .content-descripcion-corta-carrito {
    -webkit-line-clamp: 3;
  }
}
