.container-products {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.container-page-products {
  //background-color: chartreuse;
  width: 1328px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-buscadores {
  display: flex;
  flex-direction: column;
}

.container-list-products {
  background-color: white;
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
}

.container-productos {
  //max-width: 1328px;
}

.buscadores {
  display: flex;
  flex-direction: column;
}

.container-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

///notfound\

.product-notFound {
  //background-color: gray;
  display: flex;
  width: 100%;
  font-size: 2rem;
  padding: 1rem;
  margin-bottom: 5rem;
  justify-content: center;
  text-transform: uppercase;
}

@media screen and (min-width: 370px) {
  .buscadores {
    background-color: red;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .container-list {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }
  .container-list-products {
    flex-direction: row;
  }
}
@media screen and (min-width: 768px) {
  .buscadores {
    background-color: blue;
    flex-direction: row;
    width: 80%;
    justify-content: center;
  }

  .container-list {
    width: 80%;
  }
}

@media screen and (min-width: 992px) {
  .buscadores {
    background-color: yellowgreen;
    width: 100%;
    height: 60px;
    justify-content: center;
  }

  .container-list {
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    align-content: flex-start;
    flex-direction: row;
  }
}
