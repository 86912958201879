.react-calendar {
  width: 80%;
}
.react-calendar__navigation {
  background-color: $primary;
  display: flex;
}

.react-calendar__month-view__weekdays {
  background-color: $primary;
}

.react-calendar__tile--active {
  background-color: $primary;
}
// estilos

.first-section {
}
.first-section-checkout {
  padding-top: 0;
  width: 1328px;
  max-width: 100%;
  margin: auto;
  margin-top: -8rem;
  margin-bottom: 4rem;
}
.container-checkout {
  width: 80%;
  margin: auto;
}

.row-checkout {
  display: flex;
  flex-direction: column;
}

//wizard body start
.wizard-body {
  min-height: 415px;
}
.wizard-body {
  transition: all 0.3s cubic-bezier(0.68, -0.3, 0.37, 0.6);
}

@media screen and (min-width: 678px) {
  .row-body {
    display: flex;
    flex-direction: row;
  }
}

@media screen and (min-width: 978px) {
}

@media screen and (min-width: 1400px) {
  .container-checkout {
    width: 100%;
  }
}
