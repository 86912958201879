.carrito-total-container {
  border: solid 1px $gray-lighter;
  border-radius: 3px;
  background: white;
  max-width: 100%;
  margin-top: 1.5rem;
  height: 100%;
  padding-top: 1rem;
}

.carrito-total-titulo {
  margin: 0;
  padding: 0 1.2rem;
  font-size: 1.5rem;
}

.carrito-total-items {
  width: 100%;
}

.carrito-total-item {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.5rem 1.2rem;
  color: $gray;
  line-height: 1.3;
  vertical-align: top;
}

.carrito-total-item-name {
  font-size: 0.9rem;
  padding-left: 1rem;
}

.carrito-total-item-price {
  text-align: right;
  padding-right: 1rem;
}

.carrito-resume {
}

.carrito-resume-title,
.carrito-resume-price {
  padding: 1rem 1.2rem;
  color: $text-color;
  font-weight: bold;
  background-color: $lila-lighter;
  font-size: 1.5rem;
}

.text-right {
  text-align: right;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: $primary;
  margin-bottom: 10rem;
  position: absolute;
  left: 49%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: spin 1s ease infinite;

  &.spinnerMercadoPago {
    margin-top: 2rem;
    margin-bottom: 2rem;
    position: relative;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.carrito-total-buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  margin: 0;
  width: 100%;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.3);
  z-index: 4;

  .carrito-finalizar {
    @extend %btn;
    @extend %btn-primary;
    @extend %btn-md;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    width: 100%;
    margin: 1rem;
  }

  .carrito-finalizar-next {
    width: 100%;
    margin: 1rem;
  }
}

.carrito-finalizar__oculto {
  display: none;
}

.disabled {
  background: $primary-light !important;
  border: none !important;
}

.back {
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 478px) {
}

@media screen and (min-width: 678px) {
  .carrito-total-container {
    width: 35%;
    margin-top: 0;
    padding-top: 0;
    margin: 0 1rem;
  }

  .carrito-total-titulo {
    margin: 0.9rem;
  }

  .carrito-resume-price {
    padding: 0;
    padding-right: 1rem;
    color: $text-color;
    font-weight: bold;
    background-color: $lila-lighter;
    font-size: 1.3rem;
  }

  .carrito-total-buttons {
    position: static;
    width: 100%;
    background-color: white;
    padding: 0;
    box-shadow: none;
    display: flex;

    .carrito-finalizar {
      font-size: 0.9rem;
    }
  }

  .carrito-resume-title,
  .carrito-resume-price {
    font-size: 1rem;
  }
}

.mobile {
  display: none;

  transition: all 0.3s ease;
}

@media screen and (min-width: 768px) {
  .carrito-total {
    margin: 0;
    z-index: 4;
    height: 100%;
  }
}

@media screen and (min-width: 978px) {
  .carrito-total {
    width: 30%;
  }

  .carrito-total-buttons {
    .carrito-finalizar {
      font-size: 1.4rem;
    }
  }

  .carrito-resume-title,
  .carrito-resume-price {
    font-size: 1.3rem;
  }
}
