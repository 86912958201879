.notfound-container {
  background-color: #fff;
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: white url('../../assets/images/sobre-mi/fondo-sobremi.png')
    no-repeat center bottom;
  background-size: 500%;

  h3 {
    text-align: center;
  }
}

.notfound-link {
  padding-top: 2rem;

  &:hover {
    opacity: 0.7;
  }
}

@media screen and (min-width: 400px) {
  .notfound-container {
    background: white url('../../assets/images/sobre-mi/fondo-sobremi.png')
      no-repeat center bottom;
    background-size: 275%;
  }
}

@media screen and (min-width: 992px) {
  .notfound-container {
    background-size: 150%;
  }
}
