.container-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
}
.contact-title {
  font-size: 1.2rem;
  padding: 0;
  margin: 0;
  padding-bottom: 1rem;
  font-weight: bold;
  color: #393338;
}
.contact-left {
  width: 100%;
  padding-bottom: 2rem;
}

.contact-right {
  width: 100%;
  padding-bottom: 6rem;
}

.contact-right-label {
  padding: 0;
  margin: 0;
  font-size: 1.1rem;
}
.contact-buton-right {
  display: flex;
  justify-content: space-between;
}

.contact-link {
  display: flex;
  padding: 0.5rem;
  height: 3.5rem;
  align-items: center;
  .contact-icon {
    height: 3.5rem;
    width: 3.5rem;
    margin: 1rem;
    margin-left: 0;
    box-sizing: content-box;
    path {
      fill: $primary;
    }
  }
  Link:hover {
    opacity: 0.5;
    transition: all 0.3s ease;
  }
}
.contact-icon-info {
  font-size: 1.1rem;
  font-weight: bold;
  color: #393338;
}

.btn-primary {
  width: 22% !important;
}
textarea {
  width: 100%;
  max-width: 100%;
  height: 110px;
  max-height: 110px;
  resize: none;
  border-radius: 3px;
  border: solid 2px $primary;
  padding: 0.75rem 1rem;

  margin: 10px 0px;
  box-sizing: border-box;
  display: block;
}

//Animation

.one {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

.animate {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-timing-function: ease;
}

@-webkit-keyframes fadeLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeLeft {
  -webkit-animation-name: fadeLeft;
  animation-name: fadeLeft;
}
@-webkit-keyframes fadeRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
.fadeRight {
  -webkit-animation-name: fadeRight;
  animation-name: fadeRight;
}

@media screen and (min-width: 768px) {
  .container-contact {
    width: 75%;
  }
  .contact-icon-info {
    font-size: 1.3rem;
    font-weight: bold;
    color: #393338;
  }
}
@media screen and (min-width: 992px) {
  .container-contact {
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 6rem;
  }
  .contact-left {
    padding-right: 2rem;
    padding-bottom: 0;
  }
  .contact-right {
    padding-bottom: 0;
  }
  .contact-link {
    padding: 1rem;
    padding-left: 0;
  }
}
