input:not(.quantity):not([type='submit']):not([type='radio']),
select {
  width: 100%;
  border-radius: 3px;
  border: solid 2px $primary;
  padding: 0.75rem 1rem;
}

[type='search'] {
  outline-offset: 0;
  -webkit-appearance: textfield;
}

.container-filters {
  width: 90%;
  margin-bottom: 2rem;
  padding: 0;
  //margin-top: -5rem;
}

.search {
  background: url(../../assets/images/tienda/loupe.svg) no-repeat 97% center;
  background-size: 30px;
  background-position: 1rem;
}

.column {
  display: flex;
  padding-bottom: 1.5rem;
  align-items: baseline;
  flex-direction: column;
}

.row {
  display: flex;
  padding-bottom: 1.5rem;
  width: 100%;
  justify-content: space-between;
}

.container-search {
  width: 10%;
  max-width: 100%;
  order: 2;
  position: relative;
  //padding: 0 1rem;
}

.container-categories {
  width: 75%;
  min-width: 0;
  order: 1;
  padding-right: 1rem;
}

.container-order {
  width: 100%;
  //padding: 0 1rem;
}

.container-search {
  .search {
    background-color: white;
    transition: all 0.3s ease;
    position: absolute !important;
    right: 2px;
    top: 0;
    background-position: 50%;

    &::placeholder {
      opacity: 0;
    }
  }

  .search:focus {
    position: absolute !important;
    right: 2px;
    top: 0;
    width: 890% !important;
    background-color: white;
    transition: all 0.3s ease;
    z-index: 4;
    &::placeholder {
      opacity: 1;
    }

    background-position: right;
    background-position: 95%;
  }
}

.container-categorias {
}

.container-order {
}

//----------------css To Select custom

.react-select-container {
  width: 100%;
  z-index: 3;
}

.react-select__control {
  display: flex;
  border: solid 2px $primary;
}

.react-select__control--is--focused {
}

.react-select__indicator-separator {
  display: none;
}

.react-select__value-container {
  font-size: 1em;
  font-weight: 400;

  .react-select__input-container {
  }
}

.react-select__input {
  background-color: tomato;
}

.react-select__indicators {
  background-color: white;
}

.react-select__indicator {
  //background: url(../../assets/images/tienda/loupe.svg) no-repeat 80% center;
  width: 2.5rem;
}

.react-select__menu {
  background-color: violet;
  width: 100px;
  margin: 0;
  padding: 0;
}

.react-select__menu-list {
  background-color: white;
  border: solid 2px $primary;
  margin-top: -0.7rem;
}

.react-select__option {
  border-top: solid 2px $primary;
  padding: 0.75rem 1rem;

  &:first-child {
    border-top: none;
    margin-top: -4px;
  }

  &:last-child {
    margin-bottom: -4px;
  }
}
//----------------------fin select custom

//queryes------------------------------------------------------------------------
@media screen and (min-width: 370px) {
  .container-buscadores {
  }

  .container-search {
    width: 15%;
    .search:focus {
      width: 610% !important;
    }
  }

  .container-categories {
    width: 75%;
  }
}

@media screen and (min-width: 575px) {
  .container-buscadores {
  }
  .container-search {
    width: 7%;
    .search:focus {
      width: 1345% !important;
    }
  }

  .container-categories {
    width: 85%;
  }
}

@media screen and (min-width: 680px) {
  .container-buscadores {
  }
}

@media screen and (min-width: 768px) {
  .column {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .row {
    justify-content: space-between;
    width: 70%;
  }
  .container-search {
    width: 50%;
    order: 1;
  }

  .container-categories {
    width: 45%;
    order: 2;
  }

  .container-order {
    width: 30%;

    //padding: 0 1rem;
  }
  .container-search {
    .search {
      background-color: white;
      //background-position: right !important;
      background-position: 95%;
      transition: none;
      position: absolute;
      right: 2px;
      top: 0;

      &::placeholder {
        opacity: 1;
      }
    }

    .search:focus {
      position: sticky;

      max-width: 100%;

      transition: none;
    }
  }
}

@media screen and (min-width: 992px) {
  .container-buscadores {
  }
}
