/*------------------------------------*\
    # Buttons
\*------------------------------------*/

.btn,
%btn {
  padding: 0;
  background-color: transparent;
  border: 0;
  outline: none;
  vertical-align: middle;
  cursor: pointer;

  &:focus:not(.btn-elevate) {
    box-shadow: none;
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }

  &:focus-visible {
    outline: 0;
  }
}

.btn-primary,
%btn-primary {
  border-radius: 8px;
  transition: all 0.3s ease;
  font-weight: 700 !important;
  border: solid 1px;
  height: auto;
  width: auto;
  opacity: 1;
  background: $primary;
  color: white !important;
  border-color: $primary;

  &:hover {
    background-color: $primary-light;
  }
}

.btn-secondary,
%btn-secondary {
  background: white;
  color: $primary !important;
  border-color: white;
  :hover,
  :focus {
    background: white;
    color: $primary !important;
    border-color: white;
  }
}

.btn-terciary,
%btn-terciary {
  color: $gray-5c;
  background-color: $white;
  border: 1px solid $gray-5c;
  border-radius: 5px;

  &:hover {
    border: 1px solid $primary;
  }
}

.btn-block {
  width: 100%;
}

.btn-sm {
  padding: 0.5rem;
}

.btn-md,
%btn-md {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.btn-lg {
  @include font-size(15px);
  padding: 1rem 2rem;

  @media (min-width: $screen-md) {
    @include font-size(20px);
    padding: 1.4rem 2rem;
  }
}

.btn-label {
  margin-left: 0.5rem;
}

.btn-circle,
%btn-circle {
  border-radius: 50%;
}

.btn-go-back {
  @include font-size(20px);

  color: $white;
}

.btn--success {
  color: $green-59;
  border: 1px solid $green-59;
}

.btn--primary {
  color: $primary;
  border: 1px solid $primary;
}
